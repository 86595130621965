import * as React from "react";

import Title from "../../../components/Title";
import useLocales from "../../../hooks/useLocales";
import {useEffect, useRef, useState} from "react";
import * as jose from "jose";
import axios from "../../../features/axios";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {set} from "react-hook-form";


export default function Board() {
  const { translate } = useLocales();
    const { getAccessTokenSilently } = useAuth0();

    const dispatch = useDispatch();

    const isMounted = useRef(true);


    useEffect(() => {
        async function fetchData() {
            const accessToken = await getAccessTokenSilently();

            const data = {
                client_id: "GWrdpe8ZYF4caTClMry6dnh9UKNOQmDE",
                client_secret:
                    "PNK0n6J-yD6zqGjgT3qU3VI7e6xNVGiXwWqbqw-EwaalhstwFlo3GuJv_CxnHK5g",
                audience: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com/api/v2/",
                grant_type: "client_credentials",
            };

            const managementAccessToken = await axios({
                method: "post",
                baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com",
                data: data,
                url: "/oauth/token",
                headers: {
                    "content-type": "application/json",
                },
            }).catch((err) => console.log(err));

            axios({
                method: "get",
                baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com",
                url: "/userinfo",
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {

                    const authZeroId = response.data.sub;

                    axios({
                        method: "get",
                        baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com/api/v2",
                        url: "/users/" + authZeroId,
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${managementAccessToken.data.access_token}`,
                        },
                    })
                        .then((response) => {

                            const updatedObject = {
                                ...response.data.user_metadata,
                                authZeroId,
                            };

                            if(!response.data.app_metadata.user_info_missed) {
                                if (!response.data.app_metadata.is_registered) {
                                    axios({
                                        method: "post",
                                        url: "/private/users",
                                        data: updatedObject,
                                        headers: {
                                            "content-type": "application/json",
                                            Authorization: `Bearer ${accessToken}`,
                                        },
                                    })
                                        .then((response) => {

                                            if (response.status === 200) {

                                                const user_metadata = {
                                                    "app_metadata": {
                                                        "is_registered": true,
                                                        "user_info_missed": false
                                                    }
                                                };

                                                axios({
                                                    method: "patch",
                                                    baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com/api/v2",
                                                    url: "/users/" + authZeroId,
                                                    data: user_metadata,
                                                    headers: {
                                                        "content-type": "application/json",
                                                        Authorization: `Bearer ${managementAccessToken.data.access_token}`,
                                                    },
                                                })
                                                    .then((response) => {

                                                    })
                                                    .catch((err) => console.log(err));

                                            }

                                        })
                                        .catch((err) => console.log(err, "Post not succeeded"));
                                }
                            }
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        }

        // Check if the component is still mounted before updating state or performing other actions
        if (isMounted.current) {
            // Update state or perform actions here...
            fetchData();
        }

        // Cleanup function
        return () => {
            // Set the isMounted ref to false when the component is unmounted
            isMounted.current = false;
        };
    }, []);







    return (
    <React.Fragment>
      <Title>{translate("app")}</Title>

      <p>This is the content of the session token:</p>
    </React.Fragment>
  );
}
