import "./App.css";

import Router from "./routes";
import MotionLazyContainer from "./components/MotionLazyContainer";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
  return (
    <BrowserRouter>
      <Auth0Provider
        domain="dev-yj6sqn6ahr5p3vxg.uk.auth0.com"
        clientId="TBlkBzDPCZ1twhUcpOyKKjSHMVKrse1x"
        useRefreshTokens="true"
        cacheLocation="localstorage"
        scope="openid email profile"
        authorizationParams={{
        // redirect_uri: "http://localhost:3000/dashboard/general",
            redirect_uri: "https://client.favodora.com/dashboard/general",
            audience: "http://backend.favodora.com/apis",
        }}
      >
        <MotionLazyContainer>
          <Router />
        </MotionLazyContainer>
      </Auth0Provider>
    </BrowserRouter>
  );
}

export default App;
