import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import ProgressBar from "../components/ProgressBar";
import Profile from "../pages/Dashboard/Profile/Profile";
import Board from "../pages/Dashboard/General/Board";
import Campaign from "../pages/Dashboard/Campaign/Campaign";
import Dashboard from "../pages/Dashboard/Dashboard";
import Registration from "../pages/Registration";
import Home from "../pages/Home";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: "dashboard",
      element: <Dashboard />,
      children: [
        { path: "general", element: <Board /> },
        {
          path: "user",
          children: [{ path: "profile", element: <Profile /> }],
        },
        {
          path: "campaign",
          children: [{ path: "overview", element: <Campaign /> }],
        },
      ],
    },
    {
      path: "*",
      element: <Home />,
    },
    {
      path: "home",
      element: <Home />,
    },
    {
      path: "registration",
      element: <Registration />,
    },
  ]);
}
