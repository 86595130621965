// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const de = {
  demo: {
    title: `Deutsch`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  campaign: {
    title: `Campaignen`,
    beverage: `Getränk`,
    spices: `Gewürze`,
    snacks: `Snacks`,
    cheese: `Käse`,
    notDefined: "Nicht definiert",
  },
  company: `Favodora`,
  app: `Bord`,
  user: `Benutzer`,
  list: `Liste`,
  edit: `bearbeiten`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `neu erfassen`,
  kanban: `kanban`,
  general: `allgemein`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `Benutzerkonto`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `verwaltung`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  login_page: {
    title: `Anmeldung`,
    registered_info: `Bitte befolgen sie die Anweisungen im Bestätigungsmail, um die Registrierung abzuschliessen.`,
    sub_title: `Weiter zum FindMyTreat Konto`,
    remember: `speichern`,
    forgot: `Passwort vergessen?`,
    login_button: `Anmelden`,
    email_label: `Email Adresse`,
    password_label: `Passwort`,
    link_registration_1: `Noch kein Konto?`,
    link_registration_2: `registriere dich jetzt.`,
    link_login_1: `Haben sie bereits ein Konto?`,
    link_login_2: `Anmelden`,
    title_registration: `Registrierung`,
    subtitle_registration: `Registriere dich jetzt und verbessere dein Marketing mit uns.`,
    agreement_conditions: `Mit der Registrierung bin ich einverstanden mit den`,
    agreement_conditions2: `Geschäftsbedingungen`,
    agreement_conditions3: `und dem`,
    agreement_conditions4: `Datenschutz`,
    agreement_conditions5: `von FindMyTreat`,
    firstName_label: `Vorname`,
    last_label: `Nachname`,
    phone_number_label: `Telefon`,
    register_button: `Registration`,
  },
  dashboard: {
    welcome_text: `Willkommen zurück, `,
    welcome_text2: `habe einen schönen Tag.`,
    menu_home: `Startseite`,
    menu_settings: `Einstellungen`,
    menu_logout: `Abmelden`,
  },
  account_profile: {
    lastname: `Nachname`,
    firstname: `Vorname`,
    phone_number: `Telefon Nummer`,
    address: `Adresse`,
    address_additional: `Adresse Zusatz`,
    country: `Land`,
    state: `Kanton`,
    city: `Ort`,
    zipcode: `ZIP`,
    save_button: `Änderungen sichern`,
    dashboard_label: `Dashboard`,
    user_label: `Benutzer`,
    general_label: `Allgemein`,
    account_settings_label: `Konto Einstellung`,
    profile_pic_label: `Photo hochladen`,
    billing_label: `Zahlungen`,
    change_password_label: `Passwort ändern`,
    picture_allowed_types_text: `Erlaubt: *.jpeg, *.jpg, *.png, *.gif`,
    picture_allowed_size_text: `max. Grösse:`,
    account_title: `Benutzerkonto`,
  },
  error_message_login: {
    login_credentials_failed: `Dein Benutzername/Passwort ist falsch.`,
    login_server_error: `Login hat nicht funktioniert Aufgrund eines momentanen Server Errors - bitte kontaktiere den Support.`,
    email_not_valid: `Email Adresse hat falsches Format.`,
    password_required: `Passwort wird benötigt`,
    email_required: `Email wird benötigt`,
    firstname_required: `Vorname wird benötigt`,
    name_required: `Name wird benötigt`,
    phone_required: `Telefon Nummer wird benötigt`,
    phone_not_valid: `Telefon Nummer hat falsches Format.`,
    email_address_not_available: `E-Mail Adresse nicht vorhanden.`,
    password_not_valid: `das Passwort ist ungültig.`,
    confirmation_code_not_valid: `Der Bestätigungscode is falsch.`,
  },
  info_message: {
    hi: `Hi`,
  },
};

export default de;
