import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Title from "../../../components/Title";
import useLocales from "../../../hooks/useLocales";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
//import { fetchCampaignCategories } from "../../../features/utils/productCategoriesSlice";

//import { useDispatch, useSelector } from "react-redux";
//import {useEffect} from "react";
//import axios from "../../../features/axios";

export default function Campaign() {
  const { translate } = useLocales();

  const [age, setAge] = React.useState("");

  //const campaignCategoriesSelector = useSelector((state) => state.campaignCategories);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <React.Fragment>
      <Title>{translate("campaign.title")}</Title>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          {/*
 <FormControl variant="standard" sx={{ minWidth:"100%" }}>
                    <InputLabel id="demo-simple-select-label">Age</InputLabel>


                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                    >
                        {campaignCategoriesSelector ? campaignCategoriesSelector?.campaignCategories.map((category, index) => (
                            <MenuItem key={index} value={category.key}>{translate(category.value)}</MenuItem>
                        ))
                        :
                            <MenuItem value={"notDefined"}>{translate("campaign.notDefined")}</MenuItem>

                        }
                    </Select>
                    </FormControl>
                        */}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
