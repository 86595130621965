import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";

import * as jose from "jose";
import { SignJWT } from "jose";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function Registration() {
  const [sessionToken, setSessionToken] = useState(null);
  const [redirectUri, setRedirectUri] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setSessionToken(urlParams.get("session_token"));
    setRedirectUri(urlParams.get("redirect_uri"));

    setState(urlParams.get("state"));
  }, []);

  const handleFormSubmit = async (data) => {
    // Handle form submission logic here

    const inputs = data;

    const sessionTokenNew = await getEncodedSessionToken(
      inputs,
      state,
      sessionToken,
    );


    const redirectURL = redirectContinueUrl(
      sessionTokenNew,
      state,
      redirectUri,
    );

    try {
      window.location.href = redirectURL;
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const decodedSessionToken = (sessionToken) => {
    let result = { data: {} };

    try {
      // Decode the session token using the provided secret key
      // If decoding is successful, set the result to the decoded data

      result = jose.decodeJwt(sessionToken);
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const getEncodedSessionToken = async (
    additionalData,
    state,
    originalSessionToken,
  ) => {

    let payload = {};

    // If an original session token is provided, decode it
    if (originalSessionToken) {
      payload = decodedSessionToken(originalSessionToken);
    }

    // Set payload properties
    payload.iat = Math.floor(Date.now() / 1000);
    payload.state = state;
    payload.exp = Math.floor(Date.now() / 1000) + 5 * 60; // 5 minutes expiration
    payload.other = additionalData;

    // Encode the payload to generate the session token

    const header = {
      alg: "HS256", // Token generation algorithm
      typ: "JWT",
    };

    return await new SignJWT(payload)
      .setProtectedHeader(header)
      .sign(
        new TextEncoder().encode(
                "rajlhbWWBVNSlRvSfwVxhd0yz8vXwmF8UpWGU51J8GmWx5mWn6Mrwf9PmpKPI68G",
        ),
      );
  };

  const redirectContinueUrl = (sessionToken, state, redirectUri) => {
    return redirectUri + `?state=${state}&session_token=${sessionToken}`;
  };

  const { register, handleSubmit } = useForm();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                    autoComplete="given-name"
                    name="companyName"
                    {...register("companyName")}
                    required
                    fullWidth
                    id="companyName"
                    label="Company Name"
                    autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  {...register("firstName")}
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  {...register("lastName")}
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="place"
                    {...register("place")}
                    label="Place"
                    name="place"
                    autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="address"
                    {...register("address")}
                    label="Address"
                    name="address"
                    autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="postalCode"
                    {...register("postalCode")}
                    label="Postal Code"
                    name="postalCode"
                    autoComplete="family-name"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </form>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
