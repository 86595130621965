// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  company: `Favodora`,
  campaign: {
    title: `Campaign`,
    beverage: `Beverage`,
    spices: `Spices`,
    snacks: `Snacks`,
    cheese: `Cheese`,
    notDefined: "Not defined",
  },
  app: `Board`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  login_page: {
    title: `Sign in`,
    registeredInfo: `We sent you right now a confirmation email. Please follow the instructions in there to verify your email address.`,
    sub_title: `go to FindMyTreat Account`,
    remember: `remember me`,
    forgot: `forgot your password?`,
    login_button: `Login`,
    email_label: `Email Address`,
    password_label: `Password`,
    link_registration_1: `Don't have an account?`,
    link_registration_2: `get started`,
    link_login_1: `Already have an account?`,
    link_login_2: `Login`,
    title_registration: `Registration`,
    subtitle_registration: `Register now and improve your marketing with us.`,
    agreement_conditions: `By registering, I agree to FindMyTreat`,
    agreement_conditions2: `Terms of Service`,
    agreement_conditions3: `and`,
    agreement_conditions4: `Privacy Policy`,
    agreement_conditions5: ``,
    firstName_label: `First name`,
    last_label: `Last name`,
    phone_number_label: `Phone number`,
    register_button: `Register`,
  },
  dashboard: {
    welcome_text: `Welcome back, `,
    welcome_text2: `have a wonderful day.`,
    menu_home: `Home`,
    menu_settings: `Settings`,
    menu_logout: `Logout`,
  },
  account_profile: {
    lastname: `Lastname`,
    firstname: `Firstname`,
    phone_number: `Phone Number`,
    address: `Address`,
    address_additional: `Address addition`,
    country: `Country`,
    state: `State`,
    city: `City`,
    zipcode: `ZIP Code`,
    save_button: `Save Changes`,
    dashboard_label: `Dashboard`,
    user_label: `User`,
    general_label: `General`,
    account_settings_label: `Account Settings`,
    profile_pic_label: `Upload photo`,
    billing_label: `Billing`,
    change_password_label: `Change Password`,
    picture_allowed_types_text: `Allowed *.jpeg, *.jpg, *.png, *.gif`,
    picture_allowed_size_text: ` max size of`,
    account_title: `Account`,
  },
  error_message_login: {
    login_credentials_failed: `Your username and/or password is wrong`,
    login_server_error: `The login didn't work because of a server error. Please contact your support.`,
    email_not_valid: `Email must be a valid email address.`,
    password_required: `Password is required`,
    email_required: `Email is required`,
    firstname_required: `Firstname is required`,
    name_required: `Name is required`,
    phone_required: `Phone number is required`,
    phone_not_valid: `Phone number is not valid`,
    email_address_not_available: `Email address not available.`,
    password_not_valid: `The Password is not valid.`,
    confirmation_code_not_valid: `The Confirmation Code is wrong.`,
  },
  info_message: {
    hi: `Hi`,
  },
};

export default en;
