function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

export const PATH_PAGE = {
  home: "/home",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: path(ROOTS_DASHBOARD, "/general"),
  user: {
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
  },
  campaign: {
    overview: path(ROOTS_DASHBOARD, "/campaign/overview"),
  },
};
