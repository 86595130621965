import axios from "../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { referenceDataController } from "../apis";

const initialState = {
  status: "idle",
  campaignCategories: [],
};

export const fetchCampaignCategories = createAsyncThunk(
  "/categories/getCampaignCategories",
  () => {
    return axios
      .get(referenceDataController.campaignCategories)
      .then((response) => response.data);
  },
);

const productCategoriesSlice = createSlice({
  name: "getCampaignCategories",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCampaignCategories.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.campaignCategories = action.payload;
      })
      .addCase(fetchCampaignCategories.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default productCategoriesSlice.reducer;
