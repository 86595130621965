import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import { persistStore, persistReducer } from "redux-persist";

import campaignCategories from "./utils/productCategoriesSlice";

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  campaignCategories: campaignCategories,
});

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
};

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
