import * as React from "react";
import { useRecoilState } from "recoil";
import { addressState } from "../../../recoil_state";

import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Title from "../../../components/Title";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import axios from "../../../features/axios";
import { fetchCampaignCategories } from "../../../features/utils/productCategoriesSlice";

export default function Profile() {
  // const {state, action } = useStateMachine(updateAction);

  const [address, setAddressState] = useRecoilState(addressState);

  //const [registration, setRegistration] = useRecoilState(registrationStat)

  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchData() {
      const accessToken = await getAccessTokenSilently();

      const data = {
        client_id: "GWrdpe8ZYF4caTClMry6dnh9UKNOQmDE",
        client_secret:
            "PNK0n6J-yD6zqGjgT3qU3VI7e6xNVGiXwWqbqw-EwaalhstwFlo3GuJv_CxnHK5g",
        audience: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com/api/v2/",
        grant_type: "client_credentials",
      };

      const managementAccessToken = await axios({
        method: "post",
        baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com",
        data: data,
        url: "/oauth/token",
        headers: {
          "content-type": "application/json",
        },
      }).catch((err) => console.log(err));

      axios({
        method: "get",
        baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com",
        url: "/userinfo",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
          .then((response) => {

            const authZeroId = response.data.sub;

            axios({
              method: "get",
              baseURL: "https://dev-yj6sqn6ahr5p3vxg.uk.auth0.com/api/v2",
              url: "/users/" + response.data.sub,
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${managementAccessToken.data.access_token}`,
              },
            })
                .then((response) => {

                  const updatedObject = {
                    ...response.data.user_metadata,
                    authZeroId,
                  };

                  console.log(updatedObject);

                })
                .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));




      //dispatch(fetchCampaignCategories());
    }
    fetchData();
  }, [dispatch, getAccessTokenSilently]);



  const { handleSubmit, register } = useForm({
    defaultValues: address,
  });


  const onSubmit = (data) => {
    setAddressState(data);
  };

  return (
    <React.Fragment>
      <Title>Profile</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              autoComplete="given-name"
              variant="standard"
              {...register("firstName")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last name"
              fullWidth
              autoComplete="family-name"
              variant="standard"
              {...register("lastName")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label="Address line 1"
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
              {...register("address1")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              label="Address line 2"
              fullWidth
              autoComplete="shipping address-line2"
              variant="standard"
              {...register("address2")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              {...register("city")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="state"
              name="state"
              label="State/Province/Region"
              fullWidth
              variant="standard"
              {...register("state")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label="Zip / Postal code"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
              {...register("zip")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="country"
              name="country"
              label="Country"
              fullWidth
              autoComplete="shipping country"
              variant="standard"
              {...register("country")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox color="secondary" name="saveAddress" value="yes" />
              }
              label="Use this address for payment details"
            />
          </Grid>

          <Grid item xs={12}>
            <p>{address.firstName}</p>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={{ mt: 3, ml: 1 }}>Cancel</Button>

          <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
            Save
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
}
