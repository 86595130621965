import axios from "axios";
// config
export const HOST_API =
  process.env.REACT_APP_HOST_API_KEY || "http://localhost:8080/api";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong",
    ),
);

export default axiosInstance;
