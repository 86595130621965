const PRIVATE_API = "/api/v1";
const PUBLIC_API = "/public-api/v1";

export const campaignController = {
  campaigns: PRIVATE_API + "/campaigns",
};

export const referenceDataController = {
  campaignCategories: PRIVATE_API + "/campaign/categories",
};

export const personController = {
  usersPut: PRIVATE_API + "/users",
};
