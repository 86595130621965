import { atom, selector } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
const addressState = atom({
  key: "addressState",
  default: {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  },
  effects: [localStorageEffect("current_address")],
});

export { addressState };

export const resetMyDataState = selector({
  key: "resetMyDataState",
  get: ({ get }) => {
    // Dummy get callback, as it's not used for reading the value
    return get(addressState);
  },
  set: ({ set }) => {
    // Reset the Recoil state
    set(addressState, {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    });

    // Reset the localStorage
    localStorage.removeItem("current_address");
  },
});
