import { useTranslation } from "react-i18next";
import { deDE, enUS } from "@mui/material/locale";

// config

// ----------------------------------------------------------------------

const allLangs = [
  {
    label: "Deutsch",
    value: "de",
    systemValue: deDE,
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
  },
];

const defaultLang = allLangs[0]; // English
export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const langStorage = localStorage.getItem("i18nextLng");

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs,
  };
}
